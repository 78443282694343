// import { useEffect, useState, useRef } from "react";
import dynamic from "next/dynamic";
const NewsTopbar = dynamic(() => import("components/newsCard/newsTopbar"));
const TopPodcastContainer = ({ topPodcastData }) => {
  topPodcastData = topPodcastData.slice(0, 4);
  console.log("topPodcastData", topPodcastData);
  return (
    <div className="top-pod-outer">
      <div className="topShowsTopBar">
        <NewsTopbar
          videoEn={"Top Podcasts"}
          videoHn={"टॉप पॉडकास्ट"}
          newsIcon={"podcast-wid"}
          newsBgclassName={"videoShow"}
          newsBgClass={"videoShowTopBar"}
          videoTitleClass={"videoTitle"}
          videoImgClass={"videoImg"}
          videoLinkClass={"videoLink"}
        />
      </div>
      <div className="children-wrapper">
        {topPodcastData?.map((data, index) => {
          return (
            <a
              href={`/audio/detail/${data?.showSlug}/${data?.episodeSlug}`}
              // target="_blank"
              rel="noreferrer"
              key={index}
            >
              <div className="topShowsContOuter">
                <div className="left-side">
                  <img src={data?.episodeImageUrl} />
                </div>
                <div className="right-side">
                  <div className="title">{data?.episodeTitle}</div>
                  <div className="buttons">
                    <img
                      src="/assets/images/video-square.png"
                      alt="audio-icon"
                    />
                  </div>
                </div>
              </div>
              {topPodcastData?.length !== index + 1 && (
                <div
                  className="line-newsImageTextBanner1"
                  style={{
                    height: "1px",
                    background: "rgba(166, 166, 166, 0.2)",
                    width: "90%",
                    margin: "1rem auto",
                  }}
                ></div>
              )}
            </a>
          );
        })}
      </div>
    </div>
  );
};
export default TopPodcastContainer;
